.otpContainer {
  margin: 5%;
}

.otpInput {
  width: 60% !important;
  height: 150%;
  text-align: center;
  border-radius: 4px;
  border: 0.1px solid;
  margin: 0.7rem;
}

.otpInput:focus { 
  outline: none !important;
  border-color: #6251FE;
}