.otpContainer {
  margin: 3%;
}

.otpInput {
  width: 50% !important;
  height: 180%;
  text-align: center;
  border-radius: 4px;
  border: 2px solid;
  margin: 0.5rem;
}

.otpInput:focus { 
  outline: none !important;
  border-color: #6251FE;
}